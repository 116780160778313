<template>
  <div class="export-setting">
    <div class="box-setting-header">
      <div class="d-flex">
        <div class="title mr-4">Gestion les modèles exportation</div>
        <div v-if="loadingExport" class="loading">
          <div class="spinner-border loadingFetch" role="status"></div>
        </div>
      </div>

      <div>
        <b-button
          v-if="checkPermission('GMETHAME')"
          variant="success"
          class="config-btn ml-2"
          v-b-modal.addModelExportDynamique
        >
          <font-awesome-icon icon="plus" /> Ajouter un modèle d'export
        </b-button>
      </div>
    </div>
    <div class="ligne-box-setting-header mt-2"></div>
    <div class="body-box-setting export-body">
      <div class="table-rapport-style table-modele-export-height w-100-p">
        <b-table
          show-empty
          id="my-table"
          :items="models"
          :fields="computedFields"
          class="custom-table-style table-modele-export"
          bordered
          sticky-header
          hover
          head-variant="light"
          empty-text="Il n'y a aucun enregistrement à afficher"
          tbody-tr-class="ligneNormale"
        >
          <template v-slot:cell(Actions)="data">
            <b-button
              v-if="checkPermission('GMETHVME')"
              class="button-warning-style ml-2"
              size="sm"
              variant="warning"
              @click.stop.prevent="handleClickShow(data.item)"
              title="Visualiser un modal export"
            >
              <font-awesome-icon icon="eye" />
            </b-button>
            <b-button
              v-if="
                (getUserData.role == 'admin' ||
                  data.item.user_name == getUserData.name) &&
                  checkPermission('GMETHMDE')
              "
              class="button-succes-style ml-2"
              size="sm"
              variant="success"
              @click.stop.prevent="handleClickUpdate(data.item)"
              title="Modifier modèle d'export"
            >
              <font-awesome-icon icon="pencil-alt" />
            </b-button>

            <b-button
              v-if="
                (getUserData.role == 'admin' ||
                  data.item.user_name == getUserData.name) &&
                  checkPermission('GMETHSME') &&
                  !data.item.name.includes('(API)')
              "
              class="button-error-style ml-2"
              size="sm"
              variant="danger"
              @click.stop.prevent="handleClickDelete(data.item)"
              title="Supprimer  modèle d'export"
            >
              <font-awesome-icon icon="trash" />
            </b-button>
          </template>
          <template v-slot:cell(name)="data"> {{ data.item.name }}</template>
          <template v-slot:cell(user_name)="data">
            {{ data.item.user_name | CreateurFormat }}</template
          >
          <template v-slot:cell(statut)="data">
            {{ data.item.statut | StatutFormat }}</template
          >
        </b-table>
      </div>
    </div>
    <div class="footer-style mt-2">
      <b-pagination
        v-model="page"
        :per-page="perPage"
        :total-rows="modelsTotalRows"
        @change="pagination"
        aria-controls="my-table"
        pills
        align="center"
        size="sm"
        class="pagination-style"
      ></b-pagination>

      <div class="per-page-element-style">
        <div class="box-label-champ">
          <div class="label-box-style">
            <span class="title-tabel">Eléments par page</span>
          </div>
        </div>
        <b-form-select
          class="b-form-select-new-style bg-select"
          v-model="perPage"
          :options="perPageList"
          @change="changePerPage"
        ></b-form-select>
      </div>
    </div>
    <b-modal
      ref="addModelExportDynamique"
      id="addModelExportDynamique"
      title="Ajouter une année"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap modal-dialog-export"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Ajouter un modèle d'export</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('addModelExportDynamique')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.stop.prevent="submitModeleExport"
            class="form-modal-custom-style visibilty-export"
          >
            <div class="row mb-3">
              <div class="col-6">
                <b class="text-dark"> Nom Modèle</b>
              </div>
              <div class="col-5">
                <b class="text-dark"> Partagable</b>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-6">
                <b class="text-dark">
                  <b-form-group class="input-modal-champ mb-0">
                    <b-form-input required v-model="model" autocomplete="off">
                    </b-form-input> </b-form-group
                ></b>
              </div>
              <div class="col-5">
                <b class="text-dark">
                  <b-form-checkbox
                    name="flavour-1"
                    id="checkbox-group-1"
                    switch
                    class="check-th"
                    :value="true"
                    :unchecked-value="false"
                    v-model="statut"
                  >
                  </b-form-checkbox
                ></b>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-1"></div>
              <div class="col-1"></div>
              <div class="col-4">
                <b class="text-dark"> Description colonne</b>
              </div>
              <div class="col-5">
                <b class="text-dark"> Nom de colonne</b>
              </div>
            </div>
            <div class="row">
              <div class="col-1"></div>
              <div class="col-1">
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  class="check-th-details ml-2"
                  v-model="checkAll"
                  @change="checkAllColumn"
                >
                </b-form-checkbox>
              </div>
              <div class="col-4 text-justify">
                <div id="info-wrap">
                  <div class="description-column">
                    <b class="text-dark text-deco"> Tous</b>
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <draggable :list="columns">
              <div class="row" v-for="(column, index) in columns" :key="index">
                <div class="col-1">
                  <img src="@/assets/sort.png" class="sort-icon ml-2" />
                </div>
                <div class="col-1">
                  <b-form-checkbox
                    :id="'checkbox-1' + index"
                    name="checkbox-1"
                    class="check-th-details ml-2"
                    v-model="column.check"
                    @change="computedCheckColumn"
                  >
                  </b-form-checkbox>
                </div>
                <div class="col-4 text-justify">
                  <div id="info-wrap">
                    <div class="description-column">
                      <b class="text-dark text-deco">
                        {{ column.description }}</b
                      >
                    </div>
                    <div class="info-column">
                      <b-icon
                        icon="exclamation-circle-fill"
                        variant="success"
                        v-if="
                          column.code == '$num_dossier' ||
                            column.code == '$client_dossier' ||
                            column.code == '$montant_final' ||
                            column.code == '$bureau_etude' ||
                            column.code == '$installeur' ||
                            column.code == '$date_paiement_dossier' ||
                            column.code == '$date_depot'
                        "
                        title="Cette colonne pour les factures support / Master"
                      ></b-icon>
                      <b-icon
                        icon="exclamation-circle-fill"
                        variant="primary"
                        v-if="
                          column.code == '$qte' ||
                            column.code == '$pu_ht' ||
                            column.code == '$nom_produit' ||
                            column.code == '$description_produit' ||
                            column.code == '$ref_produit'
                        "
                        title="Cette colonne peut faire la duplication des factures"
                      ></b-icon>
                      <b-icon
                        icon="exclamation-circle-fill"
                        variant="warning"
                        v-if="column.code == '$comptabilite'"
                        title="Cette colonne pour l'interface comptabilité"
                      ></b-icon>
                      <b-icon
                        icon="exclamation-circle-fill"
                        variant="secondary"
                        v-if="column.code == '$sous_objet'"
                        title="Cette colonne pour les factures Sci / Mensuel"
                      ></b-icon>
                      <b-icon
                        icon="exclamation-circle-fill"
                        class="icon-export-oblige"
                        v-if="
                          column.code == '$organisme' ||
                            column.code == '$Kwh' ||
                            column.code == '$num_fac_avoir' ||
                            column.code == '$kwh_avoir' ||
                            column.code == '$ttc_avoir' ||
                            column.code == '$num_depot' ||
                            column.code == '$nom_depot' ||
                            column.code == '$ratio' ||
                            column.code == '$type' ||
                            column.code == '$master_filiale'
                        "
                        title="Cette colonne pour les factures obligées"
                      ></b-icon>
                    </div>
                  </div>
                </div>
                <div class="col-5">
                  <b-form-group class="input-modal-champ mb-0">
                    <b-form-input
                      required
                      class="input-modal-champ mb-0"
                      v-model="column.nom_colonne"
                      autocomplete="off"
                    >
                    </b-form-input>
                  </b-form-group>
                </div>
                <hr />
              </div>
            </draggable>
            <div class="actionModel">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button
                type="submit"
                class="button-valide-style mt-3"
                :disabled="loadingExport"
              >
                <span>
                  Valider
                  <div v-if="loadingExport" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      ref="DeleteModelExportDynamique"
      id="DeleteModelExportDynamique"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer un modèle d'export</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('DeleteModelExportDynamique')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.stop.prevent="deleteModeleExport"
            class="form-modal-custom-style"
          >
            <div class="mt-4 ml-3">
              <p class="text-dark">
                Êtes-vous sur de vouloir supprimer ce modèle
                <strong class="text-dark">
                  {{ modelToDelete ? modelToDelete.name : '-' }}
                </strong>
                ?
              </p>
            </div>
            <div class="actionModel">
              <b-button
                type="submit"
                class="button-valide-style mt-3"
                :disabled="loadingExport"
              >
                <span>
                  Valider
                  <div v-if="loadingExport" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      ref="updateModelExportDynamique"
      id="updateModelExportDynamique"
      title="Modifier modèle d'export"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap modal-dialog-export "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Modifier modèle d'export</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('updateModelExportDynamique')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.stop.prevent="handleSubmitUpdate"
            class="form-modal-custom-style visibilty-export"
          >
            <div class="row mb-3">
              <div class="col-5">
                <b class="text-dark"> Nom Modèle</b>
              </div>
              <div class="col-4">
                <b class="text-dark"> Partagable</b>
              </div>
              <div class="col-3">
                <b class="text-dark"> Actualiser</b>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-5">
                <b class="text-dark">
                  <b-form-group class="input-modal-champ mb-0">
                    <b-form-input
                      required
                      v-model="modelToUpdate.name"
                      autocomplete="off"
                      :disabled="
                        modelToUpdate &&
                          modelToUpdate.name &&
                          modelToUpdate.name.includes('(API)')
                      "
                    >
                    </b-form-input> </b-form-group
                ></b>
              </div>
              <div class="col-4">
                <b class="text-dark">
                  <b-form-checkbox
                    name="flavour-1"
                    id="checkbox-group-1"
                    switch
                    class="check-th"
                    :value="true"
                    :unchecked-value="false"
                    v-model="modelToUpdate.statut"
                  >
                  </b-form-checkbox
                ></b>
              </div>
              <div class="col-3">
                <img
                  src="@/assets/arrows_rotate.png"
                  class="refresh-icon-export-modal"
                  @click="handleRefreshModalExport()"
                  title="Actualiser la liste de colonne"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-1"></div>
              <div class="col-1"></div>
              <div class="col-4">
                <b class="text-dark"> Description colonne</b>
              </div>
              <div class="col-5">
                <b class="text-dark"> Nom de colonne</b>
              </div>
            </div>
            <div class="row">
              <div class="col-1"></div>
              <div class="col-1">
                <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  class="check-th-details ml-2"
                  v-model="checkAll"
                  @change="checkAllColumnUpdate"
                >
                </b-form-checkbox>
              </div>
              <div class="col-4 text-justify">
                <div id="info-wrap">
                  <div class="description-column">
                    <b class="text-dark text-deco"> Tous</b>
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <draggable :list="modelToUpdate.columns">
              <div
                class="row"
                v-for="(column, index) in modelToUpdate.columns"
                :key="index"
              >
                <div class="col-1">
                  <img src="@/assets/sort.png" class="sort-icon ml-2" />
                </div>
                <div class="col-1">
                  <b-form-checkbox
                    :id="'checkbox-1' + index"
                    name="checkbox-1"
                    class="check-th-details ml-2"
                    v-model="column.checked"
                    @change="computedModelUpdateCheck"
                  >
                  </b-form-checkbox>
                </div>
                <div class="col-4 text-justify">
                  <div id="info-wrap">
                    <div class="description-column">
                      <b class="text-dark text-deco">
                        {{ column.description }}</b
                      >
                    </div>
                    <div class="info-column">
                      <b-icon
                        icon="exclamation-circle-fill"
                        variant="success"
                        v-if="
                          column.column == '$num_dossier' ||
                            column.column == '$client_dossier' ||
                            column.column == '$montant_final' ||
                            column.column == '$bureau_etude' ||
                            column.column == '$installeur' ||
                            column.column == '$date_paiement_dossier' ||
                            column.column == '$date_depot'
                        "
                        title="Cette colonne pour les factures support / Master"
                      ></b-icon>
                      <b-icon
                        icon="exclamation-circle-fill"
                        variant="primary"
                        v-if="
                          column.column == '$qte' ||
                            column.column == '$pu_ht' ||
                            column.column == '$nom_produit' ||
                            column.column == '$description_produit' ||
                            column.column == '$ref_produit'
                        "
                        title="Cette colonne peut faire la duplication des factures"
                      ></b-icon>
                      <b-icon
                        icon="exclamation-circle-fill"
                        variant="warning"
                        v-if="column.column == '$comptabilite'"
                        title="Cette colonne pour l'interface comptabilité"
                      ></b-icon>
                      <b-icon
                        icon="exclamation-circle-fill"
                        variant="secondary"
                        v-if="column.column == '$sous_objet'"
                        title="Cette colonne pour les factures Sci / Mensuel"
                      ></b-icon>
                      <b-icon
                        icon="exclamation-circle-fill"
                        class="icon-export-oblige"
                        v-if="
                          column.column == '$organisme' ||
                            column.column == '$Kwh' ||
                            column.column == '$num_fac_avoir' ||
                            column.column == '$kwh_avoir' ||
                            column.column == '$ttc_avoir' ||
                            column.column == '$num_depot' ||
                            column.column == '$nom_depot' ||
                            column.column == '$ratio' ||
                            column.column == '$type' ||
                            column.column == '$master_filiale'
                        "
                        title="Cette colonne pour les factures obligées"
                      ></b-icon>
                    </div>
                  </div>
                </div>
                <div class="col-5">
                  <b-form-group class="input-modal-champ mb-0">
                    <b-form-input
                      :disabled="
                        column.column == '$num_facture' &&
                          modelToUpdate.is_system
                      "
                      required
                      class="input-modal-champ mb-0"
                      v-model="column.column_value"
                      autocomplete="off"
                    >
                    </b-form-input>
                  </b-form-group>
                </div>
                <hr />
              </div>
            </draggable>
            <div class="actionModel">
              <div class="messageError">
                <div class="error">
                  <div>{{ error }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style mt-3">
                <span>
                  Valider
                  <div v-if="loadingExport" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      ref="showModalModelExport"
      id="showModalModelExport"
      title="Ajouter une année"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap modal-dialog-export "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Visualiser modèle d'export</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('showModalModelExport')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form class="form-modal-custom-style" v-if="modelToShow">
            <div
              class="row"
              v-for="(column, index) in columnsChecked"
              :key="index"
            >
              <div class="col-2"></div>
              <div class="col-4 text-justify">
                <div id="info-wrap">
                  <div class="description-column">
                    <b class="text-dark text-deco"> {{ column.description }}</b>
                  </div>
                  <div class="info-column">
                    <b-icon
                      icon="exclamation-circle-fill"
                      variant="success"
                      v-if="
                        column.column == '$num_dossier' ||
                          column.column == '$client_dossier' ||
                          column.column == '$montant_final' ||
                          column.column == '$bureau_etude' ||
                          column.column == '$installeur' ||
                          column.column == '$date_paiement_dossier' ||
                          column.column == '$date_depot'
                      "
                      title="Cette colonne pour les factures support / Master"
                    ></b-icon>
                    <b-icon
                      icon="exclamation-circle-fill"
                      variant="primary"
                      v-if="
                        column.column == '$qte' ||
                          column.column == '$pu_ht' ||
                          column.column == '$nom_produit' ||
                          column.column == '$description_produit' ||
                          column.column == '$ref_produit'
                      "
                      title="Cette colonne peut faire la duplication des factures"
                    ></b-icon>
                    <b-icon
                      icon="exclamation-circle-fill"
                      variant="warning"
                      v-if="column.column == '$comptabilite'"
                      title="Cette colonne pour l'interface comptabilité"
                    ></b-icon>
                    <b-icon
                      icon="exclamation-circle-fill"
                      variant="secondary"
                      v-if="column.column == '$sous_objet'"
                      title="Cette colonne pour les factures Sci / Mensuel"
                    ></b-icon>
                    <b-icon
                      icon="exclamation-circle-fill"
                      class="icon-export-oblige"
                      v-if="
                        column.column == '$organisme' ||
                          column.column == '$Kwh' ||
                          column.column == '$num_fac_avoir' ||
                          column.column == '$kwh_avoir' ||
                          column.column == '$ttc_avoir' ||
                          column.column == '$num_depot' ||
                          column.column == '$nom_depot' ||
                          column.column == '$ratio' ||
                          column.column == '$type'
                      "
                      title="Cette colonne pour les factures obligées"
                    ></b-icon>
                  </div>
                </div>
              </div>
              <div class="col-5">
                <b-form-group class="input-modal-champ mb-0">
                  <b-form-input
                    required
                    class="input-modal-champ mb-0"
                    v-model="column.column_value"
                    autocomplete="off"
                    disabled
                  >
                  </b-form-input>
                </b-form-group>
              </div>
              <hr />
            </div>

            <div class="actionModel">
              <b-button
                type="submit"
                class="button-valide-style mt-3"
                @click.prevent.stop="hideModal('showModalModelExport')"
              >
                <span> Fermer </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters, mapActions } from 'vuex';
export default {
  data() {
    return {
      checkAll: false,
      statut: false,
      model: null,
      modelToUpdate: null,
      oldModelToUpdate: null,
      oldModalToUpdate: null,
      modelToDelete: null,
      modelToShow: null,
      columnsChecked: [],
      error: null,
      page: 1,
      perPage: 10,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      columns: [
        {
          code: '$societe',
          description: 'Sociéte vendeur',
          nom_colonne: 'Societe',
          check: false
        },
        {
          code: '$reste',
          description: 'Reste aprés avoir et paiment',
          nom_colonne: 'Reste',
          check: false
        },
        {
          code: '$type_facture',
          description: 'Type facture',
          nom_colonne: 'Type Facture',
          check: false
        },
        {
          code: '$num_facture',
          description: 'Numéro facture',
          nom_colonne: 'Numéro Facture',
          check: false
        },
        {
          code: '$client',
          description: 'Sociéte client',
          nom_colonne: 'Client',
          check: false
        },
        {
          code: '$net_ht',
          description: 'net ht',
          nom_colonne: 'NET HT',
          check: false
        },
        { code: '$tva', description: 'tva', nom_colonne: 'TVA', check: false },
        { code: '$ttc', description: 'ttc', nom_colonne: 'TTC', check: false },
        {
          code: '$statut',
          description: 'statut',
          nom_colonne: 'Statut',
          check: false
        },
        {
          code: '$iban',
          description: 'iban',
          nom_colonne: 'IBAN',
          check: false
        },
        { code: '$bic', description: 'bic', nom_colonne: 'BIC', check: false },
        {
          code: '$banque',
          description: 'bank',
          nom_colonne: 'Banque',
          check: false
        },
        {
          code: '$date_paiement',
          description: 'Date paiement',
          nom_colonne: 'Date paiement',
          check: false
        },
        {
          code: '$mode_reglement',
          description: 'Mode reglement',
          nom_colonne: 'Mode reglement',
          check: false
        },
        {
          code: '$date_creation',
          description: 'Date creation Facture',
          nom_colonne: 'Date creation Facture',
          check: false
        },
        {
          code: '$categorie',
          description: 'Categorie',
          nom_colonne: 'Catégorie',
          check: false
        },
        {
          code: '$objet',
          description: 'Objet',
          nom_colonne: 'Objet',
          check: false
        },
        {
          code: '$famille',
          description: 'Famille',
          nom_colonne: 'Famille',
          check: false
        },
        {
          code: '$num_dossier',
          description: 'Numero dossier',
          nom_colonne: 'Numero de dossier',
          check: false
        },
        {
          code: '$date_paiement_dossier',
          description: 'Date paiement dossier',
          nom_colonne: 'Date paiement dossier',
          check: false
        },
        {
          code: '$date_depot',
          description: 'Date dépôt dossier',
          nom_colonne: 'Date dépôt dossier',
          check: false
        },
        {
          code: '$client_dossier',
          description: 'Client dossier',
          nom_colonne: 'Client de dossier',
          check: false
        },
        {
          code: '$montant_final',
          description: 'Montant final',
          nom_colonne: 'Montant final',
          check: false
        },
        {
          code: '$installeur',
          description: 'Installeur',
          nom_colonne: 'Installeur',
          check: false
        },
        {
          code: '$bureau_etude',
          description: "Bureau d'étude",
          nom_colonne: "Bureau d'étude",
          check: false
        },
        {
          code: '$nom_produit',
          description: 'Nom produit',
          nom_colonne: 'Nom produit',
          check: false
        },
        {
          code: '$description_produit',
          description: 'Description produit',
          nom_colonne: 'Description produit',
          check: false
        },
        {
          code: '$ref_produit',
          description: 'Référence produit',
          nom_colonne: 'Référence produit',
          check: false
        },
        {
          code: '$qte',
          description: 'Quantité produit',
          nom_colonne: 'Quantité produit',
          check: false
        },
        {
          code: '$pu_ht',
          description: 'PU HT produit',
          nom_colonne: 'PU HT produit',
          check: false
        },
        {
          code: '$user',
          description: 'Crée par',
          nom_colonne: 'Crée par',
          check: false
        },
        {
          code: '$comptabilite',
          description: 'Comptabilisé',
          nom_colonne: 'Comptabilisé',
          check: false
        },
        {
          code: '$sous_objet',
          description: 'Mois',
          nom_colonne: 'Mois',
          check: false
        },
        {
          code: '$created_at',
          description: 'Date création',
          nom_colonne: 'Date création',
          check: false
        },
        {
          code: '$organisme',
          description: 'Organisme',
          nom_colonne: 'Organisme',
          check: false
        },
        {
          code: '$Kwh',
          description: 'Kwh cumac',
          nom_colonne: 'Kwh cumac',
          check: false
        },
        {
          code: '$num_fac_avoir',
          description: "Numéro de la facture d'avoir",
          nom_colonne: "Numéro de la facture d'avoir",
          check: false
        },
        {
          code: '$kwh_avoir',
          description: "Kwh cumac d'avoir",
          nom_colonne: "Kwh cumac d'avoir",
          check: false
        },
        {
          code: '$ttc_avoir',
          description: 'Montant TTC avoir',
          nom_colonne: 'Montant TTC avoir',
          check: false
        },
        {
          code: '$num_depot',
          description: 'Dépôt N°',
          nom_colonne: 'Dépôt N°',
          check: false
        },
        {
          code: '$nom_depot',
          description: 'Nom du dépôt',
          nom_colonne: 'Nom du dépôt',
          check: false
        },
        {
          code: '$ratio',
          description: 'Ratio',
          nom_colonne: 'Ratio',
          check: false
        },
        {
          code: '$type',
          description: 'Type',
          nom_colonne: 'Type',
          check: false
        },
        {
          code: '$master_filiale',
          description: 'Master Filiale',
          nom_colonne: 'Master Filiale',
          check: false
        },
        {
          code: '$mail_lot',
          description: 'Numéro de lot mail',
          nom_colonne: 'Numéro de lot mail',
          check: false
        },
        {
          code: '$description',
          description: 'Description',
          nom_colonne: 'Description',
          check: false
        },
        {
          code: '$reste_a_payer',
          description: 'Reste à payer',
          nom_colonne: 'Reste à payer',
          check: false
        }
      ]
    };
  },
  computed: {
    ...mapGetters([
      'models',
      'loadingExport',
      'modelsTotalRows',
      'getUserData',
      'checkPermission'
    ]),
    computedFields() {
      let fields = [
        { key: 'name', label: 'Nom', show: this.checkPermission('GMETHACN') },
        {
          key: 'user_name',
          label: 'Créateur',
          show: this.checkPermission('GMETHACR')
        },
        {
          key: 'statut',
          label: 'Statut',
          show: this.checkPermission('GMETHACS')
        },
        {
          key: 'Actions',
          label: 'Actions',
          show: true
        }
      ];
      return fields.filter(field => field.show == true);
    }
  },
  methods: {
    ...mapActions([
      'getAllModelExports',
      'AddNewModelExport',
      'updateModelExport',
      'deleteModelExport'
    ]),
    handleRefreshModalExport() {
      this.columns.forEach(item => {
        let find = this.modelToUpdate.columns.find(i => i.code == item.code);

        if (!find) {
          this.modelToUpdate.columns.push({
            ...item,
            column_value: item.nom_colonne,
            column: item.code
          });
        }
      });
    },
    computedModelUpdateCheck(value) {
      if (value == false && this.checkAll) {
        this.checkAll = false;
      } else {
        let selected = [];
        this.modelToUpdate.columns.forEach(item => {
          if (item.checked == true) {
            selected.push(item);
          }
        });
        if (this.columns.length == selected.length) [(this.checkAll = true)];
      }
    },
    checkAllColumnUpdate() {
      if (this.checkAll == true) {
        this.modelToUpdate.columns.forEach(item => {
          item.checked = true;
        });
      } else {
        this.modelToUpdate.columns.forEach(item => {
          this.oldModalToUpdate.columns.forEach(column => {
            if (column.id == item.id) {
              item.checked = column.checked;
            }
          });
        });
      }
    },
    computedCheckColumn(value) {
      if (value == false && this.checkAll) {
        this.checkAll = false;
      } else {
        let selected = [];
        this.columns.forEach(item => {
          if (item.check == true) {
            selected.push(item);
          }
        });
        if (this.columns.length == selected.length) [(this.checkAll = true)];
      }
    },
    checkAllColumn() {
      if (this.checkAll == true) {
        this.columns.forEach(item => {
          item.check = true;
        });
      } else {
        this.columns.forEach(item => {
          item.check = false;
        });
      }
    },
    handleClickShow(item) {
      this.modelToShow = item;

      this.modelToShow?.columns?.forEach(element => {
        if (element.checked == true) {
          this.columnsChecked.push(element);
        }
      });
      this.$refs['showModalModelExport'].show();
    },
    hideModal(ref) {
      this.resetModal();
      this.$refs[ref].hide();
    },
    resetModal() {
      this.columns = [
        {
          code: '$societe',
          description: 'Sociéte vendeur',
          nom_colonne: 'Societe',
          check: false
        },
        {
          code: '$reste',
          description: 'Reste aprés avoir et paiment',
          nom_colonne: 'Reste',
          check: false
        },
        {
          code: '$type_facture',
          description: 'Type facture',
          nom_colonne: 'Type Facture',
          check: false
        },
        {
          code: '$num_facture',
          description: 'Numéro facture',
          nom_colonne: 'Numéro Facture',
          check: false
        },
        {
          code: '$client',
          description: 'Sociéte client',
          nom_colonne: 'Client',
          check: false
        },
        {
          code: '$net_ht',
          description: 'net ht',
          nom_colonne: 'NET HT',
          check: false
        },
        { code: '$tva', description: 'tva', nom_colonne: 'TVA', check: false },
        { code: '$ttc', description: 'ttc', nom_colonne: 'TTC', check: false },
        {
          code: '$statut',
          description: 'statut',
          nom_colonne: 'Statut',
          check: false
        },
        {
          code: '$iban',
          description: 'iban',
          nom_colonne: 'IBAN',
          check: false
        },
        { code: '$bic', description: 'bic', nom_colonne: 'BIC', check: false },
        {
          code: '$banque',
          description: 'bank',
          nom_colonne: 'Banque',
          check: false
        },
        {
          code: '$date_paiement',
          description: 'Date paiement',
          nom_colonne: 'Date paiement',
          check: false
        },
        {
          code: '$mode_reglement',
          description: 'Mode reglement',
          nom_colonne: 'Mode reglement',
          check: false
        },
        {
          code: '$date_creation',
          description: 'Date creation Facture',
          nom_colonne: 'Date creation Facture',
          check: false
        },
        {
          code: '$categorie',
          description: 'Categorie',
          nom_colonne: 'Catégorie',
          check: false
        },
        {
          code: '$objet',
          description: 'Objet',
          nom_colonne: 'Objet',
          check: false
        },
        {
          code: '$famille',
          description: 'Famille',
          nom_colonne: 'Famille',
          check: false
        },
        {
          code: '$num_dossier',
          description: 'Numero dossier',
          nom_colonne: 'Numero de dossier',
          check: false
        },
        {
          code: '$date_paiement_dossier',
          description: 'Date paiement dossier',
          nom_colonne: 'Date paiement dossier',
          check: false
        },
        {
          code: '$date_depot',
          description: 'Date dépôt dossier',
          nom_colonne: 'Date dépôt dossier',
          check: false
        },
        {
          code: '$client_dossier',
          description: 'Client dossier',
          nom_colonne: 'Client de dossier',
          check: false
        },
        {
          code: '$montant_final',
          description: 'Montant final',
          nom_colonne: 'Montant final',
          check: false
        },
        {
          code: '$installeur',
          description: 'Installeur',
          nom_colonne: 'Installeur',
          check: false
        },
        {
          code: '$bureau_etude',
          description: "Bureau d'étude",
          nom_colonne: "Bureau d'étude",
          check: false
        },
        {
          code: '$nom_produit',
          description: 'Nom produit',
          nom_colonne: 'Nom produit',
          check: false
        },
        {
          code: '$description_produit',
          description: 'Description produit',
          nom_colonne: 'Description produit',
          check: false
        },
        {
          code: '$ref_produit',
          description: 'Référence produit',
          nom_colonne: 'Référence produit',
          check: false
        },
        {
          code: '$qte',
          description: 'Quantité produit',
          nom_colonne: 'Quantité produit',
          check: false
        },
        {
          code: '$pu_ht',
          description: 'PU HT produit',
          nom_colonne: 'PU HT produit',
          check: false
        },
        {
          code: '$user',
          description: 'Crée par',
          nom_colonne: 'Crée par',
          check: false
        },
        {
          code: '$comptabilite',
          description: 'Comptabilisé',
          nom_colonne: 'Comptabilisé',
          check: false
        },
        {
          code: '$sous_objet',
          description: 'Mois',
          nom_colonne: 'Mois',
          check: false
        },
        {
          code: '$created_at',
          description: 'Date création',
          nom_colonne: 'Date création',
          check: false
        },
        {
          code: '$organisme',
          description: 'Organisme',
          nom_colonne: 'Organisme',
          check: false
        },
        {
          code: '$Kwh',
          description: 'Kwh cumac',
          nom_colonne: 'Kwh cumac',
          check: false
        },
        {
          code: '$num_fac_avoir',
          description: "Numéro de la facture d'avoir",
          nom_colonne: "Numéro de la facture d'avoir",
          check: false
        },
        {
          code: '$kwh_avoir',
          description: "Kwh cumac d'avoir",
          nom_colonne: "Kwh cumac d'avoir",
          check: false
        },
        {
          code: '$ttc_avoir',
          description: 'Montant TTC avoir',
          nom_colonne: 'Montant TTC avoir',
          check: false
        },
        {
          code: '$num_depot',
          description: 'Dépôt N°',
          nom_colonne: 'Dépôt N°',
          check: false
        },
        {
          code: '$nom_depot',
          description: 'Nom du dépôt',
          nom_colonne: 'Nom du dépôt',
          check: false
        },
        {
          code: '$ratio',
          description: 'Ratio',
          nom_colonne: 'Ratio',
          check: false
        },
        {
          code: '$type',
          description: 'Type',
          nom_colonne: 'Type',
          check: false
        },
        {
          code: '$master_filiale',
          description: 'Master Filiale',
          nom_colonne: 'Master Filiale',
          check: false
        },
        {
          code: '$mail_lot',
          description: 'Numéro de lot',
          nom_colonne: 'Numéro de lot',
          check: false
        },
        {
          code: '$description',
          description: 'Description',
          nom_colonne: 'Description',
          check: false
        },
        {
          code: '$reste_a_payer',
          description: 'Reste à payer',
          nom_colonne: 'Reste à payer',
          check: false
        }
      ];
      this.model = '';
      this.modelToUpdate = {
        statut: false
      };
      this.oldModelToUpdate = null;
      this.oldModalToUpdate = null;

      this.modelToDelete = null;
      this.modelToShow = null;
      this.columnsChecked = [];
      this.page = 1;
      this.perPage = 10;
      this.checkAll = false;
    },
    async submitModeleExport() {
      this.error = null;
      let selected = [];
      this.columns.map(column => {
        if (column.check == true) {
          selected.push(column);
        }
      });
      if (selected.length == 0) {
        this.error = 'Séléctionner au moins un champ';
      } else {
        let bodyFormData = new FormData();
        bodyFormData.append('name', this.model);
        if (this.statut == true) {
          this.statut = 1;
        } else {
          this.statut = 0;
        }
        bodyFormData.append('statut', this.statut);

        for (let i = 0; i < this.columns.length; i++) {
          bodyFormData.append(
            'columns[' + i + '][column]',
            this.columns[i].code
          );
          bodyFormData.append(
            'columns[' + i + '][description]',
            this.columns[i].description
          );
          bodyFormData.append(
            'columns[' + i + '][column_value]',
            this.columns[i].nom_colonne
          );
          bodyFormData.append('columns[' + i + '][index]', i);
          if (this.columns[i] && this.columns[i].check) {
            bodyFormData.append('columns[' + i + '][blocked]', 0);
          } else {
            bodyFormData.append('columns[' + i + '][blocked]', 1);
          }
        }
        const response = await this.AddNewModelExport(bodyFormData);
        if (response) {
          this.$refs['addModelExportDynamique'].hide();
          this.resetModal();
        } else {
          this.error = 'Une error est survenue';
        }
      }
    },
    handleClickUpdate(item) {
      // this.modelToUpdate = { ...item };
      this.modelToUpdate = JSON.parse(JSON.stringify(item));
      this.oldModelToUpdate = item;
      let selected = [];
      this.modelToUpdate.columns.forEach(item => {
        if (item.checked == true) {
          selected.push(item);
        }
      });
      if (this.modelToUpdate.columns.length == selected.length)
        [(this.checkAll = true)];
      this.$refs['updateModelExportDynamique'].show();
    },
    async handleSubmitUpdate() {
      this.error = null;
      let selected = [];
      this.modelToUpdate.columns.map(column => {
        if (column.checked == true) {
          selected.push(column);
        }
      });
      if (selected.length == 0) {
        this.error = 'Séléctionner au moins un champ';
      } else {
        let bodyFormData = new FormData();
        bodyFormData.append('id', this.modelToUpdate.id);
        bodyFormData.append('name', this.modelToUpdate.name);
        if (this.modelToUpdate.statut == true) {
          this.modelToUpdate.statut = 1;
        } else {
          this.modelToUpdate.statut = 0;
        }
        bodyFormData.append('statut', this.modelToUpdate.statut);

        for (let i = 0; i < this.modelToUpdate.columns.length; i++) {
          bodyFormData.append(
            'columns[' + i + '][id]',
            this.modelToUpdate.columns[i].id
          );

          bodyFormData.append(
            'columns[' + i + '][column]',
            this.modelToUpdate.columns[i].column
          );
          bodyFormData.append(
            'columns[' + i + '][description]',
            this.modelToUpdate.columns[i].description
          );
          bodyFormData.append(
            'columns[' + i + '][column_value]',
            this.modelToUpdate.columns[i].column_value
          );
          bodyFormData.append('columns[' + i + '][index]', i);
          if (
            this.modelToUpdate.columns[i] &&
            this.modelToUpdate.columns[i].checked
          ) {
            bodyFormData.append('columns[' + i + '][blocked]', 0);
          } else {
            bodyFormData.append('columns[' + i + '][blocked]', 1);
          }
        }
        let payload = {
          oldModelToUpdate: this.oldModelToUpdate,
          newModel: bodyFormData
        };
        const response = await this.updateModelExport(payload);
        if (response) {
          this.$refs['updateModelExportDynamique'].hide();
          this.resetModal();
        } else {
          this.error = 'Une erreur est survenue';
        }
      }
    },
    handleClickDelete(item) {
      this.modelToDelete = item;
      this.$refs['DeleteModelExportDynamique'].show();
    },
    async deleteModeleExport() {
      const response = await this.deleteModelExport(this.modelToDelete.id);
      if (response) {
        this.$refs['DeleteModelExportDynamique'].hide();
      } else {
        this.error = 'Une erreur est survenue';
      }
    },
    async pagination(paginate) {
      this.page = paginate;
      await this.getAllModelExports({
        per_page: this.perPage,
        page: this.page
      });
    },
    async changePerPage() {
      this.page = 1;
      await this.getAllModelExports({
        per_page: this.perPage,
        page: this.page
      });
    }
  },
  components: {
    draggable,
    Card: () => import('../component/card.vue')
  },
  filters: {
    CreateurFormat: value => {
      if (value == 'admin') {
        return 'Système';
      } else {
        return value;
      }
    },
    StatutFormat: value => {
      if (value == true) {
        return 'Partagé';
      } else {
        return 'Non partagé';
      }
    }
  },
  mounted() {
    this.getAllModelExports({ per_page: this.perPage, page: this.page });
  }
};
</script>
<style lang="scss" scoped>
.export-setting {
  .export-body {
    // display: flex;
    margin: 5px 15px;
    height: calc(100vh - 197px) !important;
    .table-modele-export-height {
      width: 100%;
      .title {
        color: #515356;
        display: block;
        font-weight: 600;
        margin: 5px;
      }
      .input-modal-champ {
        input {
          border-radius: 5px;
        }
      }
      .custom-table-style {
        padding: 5px;
        max-height: calc(100vh - 226px) !important;
        height: calc(100vh - 226px) !important;
        .button-danger-style,
        .btn-secondary {
          border-radius: 6px;
          padding: 3px 5px;
          font-size: 12px;
          width: 25px;
          height: 25px;
          margin: 2px;
        }
      }
    }
  }
}
.sort-icon {
  height: 20px;
  width: 20px;
}
.refresh-icon-export-modal {
  height: 16px;
  width: 27px;
  background: #4d4bac;
  border-radius: 15px;
  cursor: pointer;
}
</style>
<style lang="scss">
.icon-export-oblige {
  color: rgb(227, 106, 20);
}
.table-modele-export-height {
  background-color: #ffffff;
  border-radius: 25px;
  width: calc(100% - 205px);
  position: relative;
  padding: 7px;
  display: inline-grid;
}
.table-modele-export-height {
  max-height: calc(100vh - 218px) !important;
  height: calc(100vh - 218px) !important;
}
.export-dynamic {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;

  .table-modele-export {
    max-height: calc(100vh - 280px) !important;
    height: calc(100vh - 280px) !important;
    margin-bottom: 0px;
    .button-danger-style {
      font-family: 'Montserrat', sans-serif;
      font-size: 11px;
      font-weight: 600;
      color: #ffffff;
      border-radius: 25px;
      padding: 6px 15px;
      border: none;
    }
    .button-warning-style {
      background-color: #dc3c3c;
      font-family: 'Montserrat', sans-serif;
      font-size: 11px;
      font-weight: 600;
      color: #ffffff;
      border-radius: 25px;
      padding: 6px 11px;
      border: none;
    }
  }
  .chargement-message-text {
    text-align: left;
  }
}
.table-modele-export {
  .table {
    display: table;
  }
  td,
  th {
    padding: 5px !important;
  }
}

.button-warning-style {
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 25px;
  padding: 6px 15px;
  border: none;
}

#info-wrap {
  overflow: hidden;
}

.description-column {
  width: 90%;
  float: left;
}
.info-column {
  width: 10%;
  float: left;
}
.visibilty-export {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #4d4bac !important;
    background-color: #4d4bac !important;
  }
  .multiselect__tag {
    background: #4d4bac;
  }
  .multiselect__tag-icon:after {
    color: #ffffff;
  }
  #checkbox-group-1 {
    text-transform: capitalize;
    margin: auto;
    display: table;
  }
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
</style>
